<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_2 font_bold mb_23">Feel Good Stretches</h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0"
                                 :to="{ name: 'user_home' }">
                                 Home
                              </router-link></li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'travel_for_work' }">Travel For Work</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'airplane_travel' }">Airplane Travel</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'on_the_road' }">On The Road</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'overnight_travel' }">Overnight Travel</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'working_on_the_go' }">Working On the Go</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'self_care' }">Self Care</router-link>
                           </li>
                           <li class="breadcrumb-item active" aria-current="page">Feel Good Stretches</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- pagination Starts -->
      <section class="pagination_sec">
         <div class="pagination-nav">
            <div class="container-fluid custome_container">
               <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="row justify-content-between">
                        <div class="col-md-12">
                           <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-between mb_32">
                                 <li class="page-item">
                                    <router-link class="page-link border-0 text_black" aria-label="Previous"
                                       :to="{ name: 'self_care' }">
                                       <span aria-hidden="true">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631"
                                             viewBox="0 0 43.575 29.631">
                                             <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                                <path id="Path_30" data-name="Path 30"
                                                   d="M1755.8,353.578l-10.914,10.889,10.937,10.965"
                                                   transform="translate(-1744.886 -353.656)" fill="none"
                                                   stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5" />
                                                <path id="Path_31" data-name="Path 31"
                                                   d="M1755.8,353.578l-10.914,10.889,10.937,10.965"
                                                   transform="translate(-1728.886 -353.656)" fill="none"
                                                   stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5" />
                                             </g>
                                          </svg>
                                          Previous
                                       </span>
                                    </router-link>
                                 </li>
                                 <li class="page-item">
                                    <router-link class="page-link border-0 text_black" :to="{ name: 'travel_for_work' }"
                                       aria-label="Next">
                                       <span aria-hidden="true" class="font_bold">
                                          <span class="text_primary">Up Next<span class="mobile_hide">:</span></span>
                                          <span class="mobile_hide">&nbsp;Travel for work</span>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631"
                                             viewBox="0 0 33.575 29.631" style="margin-left:5px;">
                                             <g id="Right_arrow" data-name="Right arrow"
                                                transform="translate(-12884.354 20078.389)">
                                                <path id="Path_30" data-name="Path 30"
                                                   d="M1744.908,353.578l10.914,10.889-10.937,10.965"
                                                   transform="translate(11159.357 -20428.078)" fill="none"
                                                   stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5" />
                                                <path id="Path_31" data-name="Path 31"
                                                   d="M1744.908,353.578l10.914,10.889-10.937,10.965"
                                                   transform="translate(11143.357 -20428.078)" fill="none"
                                                   stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5" />
                                             </g>
                                          </svg>
                                       </span>
                                    </router-link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- pagination Ends -->
      <!-- Ergo On the go Starts -->
      <section class="home_stretches">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-xxl-8 col-md-12">
                  <div class="mb_40">
                     <h4 class="mb_20 font_bold heading_4">You deserve a break</h4>
                     <p class="p_22 mb_60">WFH is especially challenging when it comes to your posture, and therefore, your
                        comfort. So make it a point to take stretch breaks throughout your day to steer clear of muscle
                        tension and to learn how to destress.</p>
                  </div>
                  <div class="mb_44">
                     <p class="p_22 text_black font_bold mb_60">Suggested Videos </p>
                     <div class="row justify-content-center">
                        <div class="col-md-12 col-lg-11">
                           <div class="row">
                              <div class="col-md-6">
                                 <div class="video_card mb_40">
                                    <div class="video_img">
                                       <!-- <div class="position-absolute video_text_box">
                                          <h5 class="text-uppercase font_size_24 text_white">Stress buster series</h5>
                                          </div> -->
                                       <img
                                          src="./../assets/images/travel-for-work/feel-good-stretches-dynamic-neck-stretch.png"
                                          alt="img" class="border_dovegray">
                                    </div>
                                    <a class="font_size_18 text_balticsea mt_18 d-inline-flex align-items-center"
                                       data-bs-toggle="modal" data-bs-target="#exampleModal1"> <svg
                                          xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31"
                                          viewBox="0 0 31 31">
                                          <circle id="bg" cx="15.5" cy="15.5" r="15.5" />
                                          <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff"
                                             stroke-linejoin="round" stroke-miterlimit="10">
                                             <path
                                                d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z"
                                                stroke="none" />
                                             <path
                                                d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z"
                                                stroke="none" fill="#fff" />
                                          </g>
                                       </svg>Dynamic Neck Stretch</a>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="video_card mb_40">
                                    <div class="video_img">
                                       <!-- <div class="position-absolute video_text_box">
                                          <h5 class="text-uppercase font_size_24 text_white">Stress buster series</h5>
                                          </div> -->
                                       <img src="./../assets/images/travel-for-work/feel-good-stretches-leg-stretch.png"
                                          alt="img" class="border_dovegray">
                                    </div>
                                    <a class="font_size_18 text_balticsea mt_18 d-inline-flex align-items-center"
                                       data-bs-toggle="modal" data-bs-target="#exampleModal2"> <svg
                                          xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31"
                                          viewBox="0 0 31 31">
                                          <circle id="bg" cx="15.5" cy="15.5" r="15.5" />
                                          <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff"
                                             stroke-linejoin="round" stroke-miterlimit="10">
                                             <path
                                                d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z"
                                                stroke="none" />
                                             <path
                                                d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z"
                                                stroke="none" fill="#fff" />
                                          </g>
                                       </svg> Leg Stretch</a>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="video_card mb_40">
                                    <div class="video_img">
                                       <!-- <div class="position-absolute video_text_box">
                                          <h5 class="text-uppercase font_size_24 text_white">Stress buster series</h5>
                                          </div> -->
                                       <img
                                          src="./../assets/images/travel-for-work/feel-good-stretches-side-body-stretch.png"
                                          alt="img" class="border_dovegray">

                                    </div>
                                    <a class="font_size_18 text_balticsea mt_18 d-inline-flex align-items-center"
                                       data-bs-toggle="modal" data-bs-target="#exampleModal3"> <svg
                                          xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31"
                                          viewBox="0 0 31 31">
                                          <circle id="bg" cx="15.5" cy="15.5" r="15.5" />
                                          <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff"
                                             stroke-linejoin="round" stroke-miterlimit="10">
                                             <path
                                                d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z"
                                                stroke="none" />
                                             <path
                                                d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z"
                                                stroke="none" fill="#fff" />
                                          </g>
                                       </svg> Side Body Stretch</a>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="video_card mb_40">
                                    <div class="video_img">
                                       <!-- <div class="position-absolute video_text_box">
                                          <h5 class="text-uppercase font_size_24 text_white">Stress buster series</h5>
                                          </div> -->
                                       <img
                                          src="./../assets/images/travel-for-work/feel-good-stretches-sternum-stretch.png"
                                          alt="img" class="border_dovegray">
                                    </div>
                                    <a class="font_size_18 text_balticsea mt_18 d-inline-flex align-items-center"
                                       data-bs-toggle="modal" data-bs-target="#exampleModal4"> <svg
                                          xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31"
                                          viewBox="0 0 31 31">
                                          <circle id="bg" cx="15.5" cy="15.5" r="15.5" />
                                          <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff"
                                             stroke-linejoin="round" stroke-miterlimit="10">
                                             <path
                                                d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z"
                                                stroke="none" />
                                             <path
                                                d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z"
                                                stroke="none" fill="#fff" />
                                          </g>
                                       </svg> Sternum Stretch</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Ergo On the go Ends -->
      <section class="want_more_stretches mb_100 ">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bg_primary">
                     <div class="row">
                        <div class="col-md-12 col-lg-6 align-self-center">
                           <div class="left-section pt_42 pb_54 pl_50 mt_6">
                              <h6 class="sub_heading_1  mb_0 text-white ">Want some more stretches?
                              </h6>
                              <p class="text-white font_size_24 fst-italic mb_0">Check out our full video library </p>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-6 align-self-center text-lg-end">
                           <div class="right-section pt_26 pb_26 pl_60 pr_40">
                              <div class="mt_14">
                                 <router-link :to="{ name: 'ergo_video_library' }"
                                    class="btn btn_secondary text-capitalize font_size_16">
                                    Video Library
                                 </router-link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- More Travel Tipsstart  -->
      <section class="more_lab_tips bg_grey pt_42">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-11 px_20">
                  <div class="row">
                     <div class="col-md-12">
                        <h4 class="heading_4 font_bold mb-0">
                           More Travel Tips
                        </h4>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'airplane_travel' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/travel-for-work/airplane-travel.jpg" alt="img"
                                    class="img-fluid" />
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">
                                    Airplane Travel
                                 </h6>
                                 <p>
                                    Travel tips and tricks to keep you comfy on the go.
                                 </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'on_the_road' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/travel-for-work/on-the-road.jpg" alt="img" class="img-fluid" />
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">On The Road</h6>
                                 <p>Learn to set yourself up and feel great anywhere.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'overnight_travel' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/travel-for-work/overnight-travel.jpg" alt="img"
                                    class="img-fluid" />
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Overnight Travel</h6>
                                 <p>Sleep well, eat clean and keep up your self-care routines.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'working_on_the_go' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/travel-for-work/working-on-the-go.jpg" alt="img"
                                    class="img-fluid" />
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Working On The Go</h6>
                                 <p>Did you know that it is easy to set your self up wherever you go?</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'self_care' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/travel-for-work/self-care.jpg" alt="img" class="img-fluid" />
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">
                                    Self Care
                                 </h6>
                                 <p>Don't let your travels disrupt your healthy routines.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'feel_good_stretches' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/travel-for-work/feel-good-stretches.jpg" alt="img"
                                    class="img-fluid" />
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">
                                    Feel Good Stretches
                                 </h6>
                                 <p>
                                    Treat your body to these feel good stretches anywhere.
                                 </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- More Travel Tips end  -->
      <!-- Video Modal start Here -->
      <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
               <div class="modal-body">

                  <div class="text-end">
                     <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                     <iframe id="closed"
                        src="https://player.vimeo.com/video/327604304?h=6b95c558d1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                        title="Dynamic Neck Stretch"></iframe>

                  </div>
               </div>

            </div>
         </div>
      </div>
      <!-- Video Modal End Here -->
      <!-- Video Modal start Here -->
      <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal2" tabindex="-1"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
               <div class="modal-body">

                  <div class="text-end">
                     <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal1"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                     <iframe id="closed1"
                        src="https://player.vimeo.com/video/327607829?h=1fb7141277&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                        style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Leg Stretch"></iframe>

                  </div>
               </div>

            </div>
         </div>
      </div>
      <!-- Video Modal End Here -->
      <!-- Video Modal start Here -->
      <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal3" tabindex="-1"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
               <div class="modal-body">

                  <div class="text-end">
                     <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal2"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                     <iframe id="closed2"
                        src="https://player.vimeo.com/video/327612395?h=127a11a3f0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                        style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Side Body Stretch"></iframe>

                  </div>
               </div>

            </div>
         </div>
      </div>
      <!-- Video Modal End Here -->
      <!-- Video Modal start Here -->
      <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal4" tabindex="-1"
         aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
               <div class="modal-body">

                  <div class="text-end">
                     <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal3"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                     <iframe id="closed3"
                        src="https://player.vimeo.com/video/327613203?h=dde68b974e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                        style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Sternum Stretch"></iframe>

                  </div>
               </div>

            </div>
         </div>
      </div>
      <!-- Video Modal End Here -->
   </div>
</template>

<script>
export default {
   data() {
      return {
      }
   },
   name: "feel_good_stretches",
   methods: {
      hideModal() {
         document.getElementById('closed').src += '';
      },
      hideModal1() {
         document.getElementById('closed1').src += '';
      },
      hideModal2() {
         document.getElementById('closed2').src += '';
      },
      hideModal3() {
         document.getElementById('closed3').src += '';
      },

   }
}
</script>